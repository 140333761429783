<template>
  <div>
    <a-spin :spinning="formState.loader" class size="large">
      <sdPageHeader :title="id ? 'Edit User' : 'Add User'">
        <template v-slot:buttons></template>
      </sdPageHeader>
      <Main>
        <a-form
          :model="formState"
          name="basic"
          :rules="rules"
          autocomplete="off"
          @finish="addUserData()"
          @finishFailed="onFinishFailed"
        >
          <a-row :gutter="25">
            <a-col :xs="24">
              <FormValidationWrap>
                <VerticalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="firstName" name="firstName" label="First Name">
                        <a-input v-model:value="formState.firstName" placeholder="Eg. Doe" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="lastName" name="lastName" label="Last Name">
                        <a-input v-model:value="formState.lastName" placeholder="Eg. Doe" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="username" name="username" label="Username">
                        <a-input v-model:value="formState.username" placeholder="Eg. xyz@***" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="email" name="email" label="email">
                        <a-input v-model:value="formState.email" placeholder="Eg. xyz@***" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item
                        ref="email_verified"
                        name="email_verified"
                        label="email_verified"
                      >
                        <a-input
                          v-model:value="formState.email_verified"
                          placeholder="Eg. xyz@***"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="password" name="password" label="password">
                        <a-input v-model:value="formState.password" placeholder="Eg. xyz@***" />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </VerticalFormStyleWrap>
              </FormValidationWrap>
            </a-col>

            <a-col :xs="24">
              <FormValidationWrap>
                <VerticalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :md="8" :xs="24"></a-col>
                    <a-col :md="8" :xs="24"></a-col>
                    <a-col :md="8" :xs="24"></a-col>
                  </a-row>
                </VerticalFormStyleWrap>
              </FormValidationWrap>
            </a-col>

            <a-col :xs="24">
              <sdCards title="Notes">
                <a-col :md="8" :xs="24">
                  <a-form-item ref="Note" name="Note" label="Note">
                    <a-textarea
                      v-model:value="formState.Note"
                      placeholder="You can write something here..."
                      :rows="4"
                    />
                  </a-form-item>
                </a-col>
              </sdCards>
            </a-col>
            <a-col
              :lg="{ span: 4, offset: 20 }"
              :md="{ span: 6, offset: 18 }"
              :xs="{ span: 24, offset: 0 }"
              :sm="{ span: 6, offset: 18 }"
            >
              <sdCards>
                <sdButton type="primary" html-type="submit">
                  {{
                  id ? "Update User" : "Add User"
                  }}
                </sdButton>
              </sdCards>
            </a-col>
          </a-row>
        </a-form>
      </Main>
    </a-spin>
  </div>
</template>

<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap
} from "../../forms/overview/Style";
import { Main } from "../../styled";
import Parse from "parse";
import countriesData from "../../../helper/countryData.json";
import { onMounted } from "vue";

import { defineComponent, reactive } from "vue";
import PropTypes from "vue-types";
import { message } from "ant-design-vue";
import crew from "../../../server/user.js";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Form",
  props: {
    id: {
      required: false,
      type: PropTypes.String
    }
  },
  components: {
    Main,
    FormValidationWrap,
    VerticalFormStyleWrap
  },
  setup() {
    const router = useRoute();
    const onFinish = values => {
      console.log("Success:", values);
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const addUserData = async () => {
      // console.log("Adding User");
      const AddCrew = Parse.Object.extend("_User");
      const addCrew = new AddCrew();
      formState.loader = true;
      const acl = new Parse.ACL(Parse.User.current());
      addCrew.set({
        firstName: formState.firstName,
        lastName: formState.lastName,
        username: formState.username,
        email: formState.email,
        email_verified: formState.email_verified,
        password: formState.password
      });
       addCrew.setACL(acl);
      addCrew.save().then(async () => {
        // var acl = new Parse.ACL();
        // acl.setReadAccess('mGHp2kbjRp', true);
        // acl.setWriteAccess('mGHp2kbjRp', true);
        // acl.setReadAccess('1233u', true);
        // acl.setWriteAccess('1234', true);
        //  addCrew.setACL(acl);
        // await addCrew.save();

        // console.log(crew);
        // console.log(" User added successfully");

        message.success("New User is added ");

        (formState.firstName = ""),
          (formState.lastName = ""),
          (formState.username = ""),
          (formState.email = ""),
          (formState.email_verified = ""),
          (formState.password = ""),
          // console.log(crew);
        formState.loader = false;

        error => {
          message.error("user is not updated ");

          console.log(error);
          formState.loader = false;
        };
        // );
      });
      // }
    };
    // };
    const data1 = Object.keys(countriesData);

    onMounted(() => {
      // console.log(formState.firstName);

      //  get crew details to update
      if (formState.id) {
        crew.getPointer(formState.id).then(
          obj => {
            const firstName = obj.get("firstName");
            const lastName = obj.get("lastName");
            const username = obj.get("username");
            const email = obj.get("email");
            const email_verified = obj.get("emailVarified");
            const password = obj.get("password");
            formState.firstName = firstName;
            formState.lastName = lastName;
            formState.username = username;
            formState.email = email;
            formState.email_verified = email_verified;
            formState.password = password;
          },
          error => {
            console.log(error);
          }
        );
      }
    });

    const handleButtonClick = e => {
      console.log(e);
    };

    const formState = reactive({
      layout: "vertical",
      id: router.params.id,
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      email_verified: "",
      password: "",
      realmId: "",
      loader: false
    });

    const rules = {
      firstName: [
        {
          required: true,
          message: "Please input First name",
          trigger: "blur"
        }
      ],
      lastName: [
        {
          required: true,
          message: "Please input Last name",
          trigger: "blur"
        }
      ],
      username: [
        {
          required: true,
          message: "Please input username",
          trigger: "blur"
        }
      ],
      email: [
        {
          required: true,
          message: "Please input email",
          trigger: "blur"
        }
      ],
      email_verified: [
        {
          //   required: true,
          message: "Please input emailvarified",
          trigger: "blur"
        }
      ],
      // authData: [
      //   {
      //     required: true,
      //     message: "Please input AuthData",
      //     trigger: "blur",
      //   },
      // ],
      // Airline: [
      //   {
      //     required: true,
      //     message: "Please input Airline",
      //     trigger: "blur",
      //   },
      // ],
      password: [
        {
          required: true,
          message: "Please input password",
          trigger: "blur"
        }
      ]

      // Note: [
      //   {
      //     required: true,
      //     message: "Please Input Note",
      //     trigger: "blur",
      //   },
      // ],
    };

    const onSubmit = () => {
      console.log("hello");
    };

    return {
      formState,
      rules,
      onSubmit,
      handleButtonClick,
      addUserData,
      onFinish,
      onFinishFailed,
      data1,
      countriesData
    };
  }
});
</script>
